import { ButtonProps, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { Button } from '@/components/UI/Buttons/Button';

import BasketConfig from '@/constants/basket';
import { AddToCartProps, ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { BasketIcon } from '../Icons';
import { useApp } from '../../../context/appContext';

const AddToCartUnlogged = (
    props: AddToCartProps & ButtonProps & { grossPrice: string; isCatalogue?: boolean }
) => {
    const { orderingAllowed, showDisabledOrderingPopup } = useApp();
    const { code, grossPrice, quantity, setQuantity, icon, maxOrder, isCatalogue, ...rest } = props;
    const { unloggedIncreaseItemQuantity, cartItemsUnlogged } = useShoppingCart();
    const { errorToast, infoToast, successToast } = useToast();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const obj = useMemo(
        () => ({ code, grossPrice, quantity, isCatalogue, setQuantity, icon, maxOrder }),
        [code, grossPrice, quantity, isCatalogue, setQuantity, icon, maxOrder]
    );
    const catalogue = isCatalogue ?? false;
    const addToCart = async (id: ProductIdProps, quantity: number, grossPrice: string) => {
        if (!orderingAllowed) {
            showDisabledOrderingPopup();
            return;
        }

        setIsLoading(true);

        if (maxOrder !== undefined && quantity > maxOrder) {
            infoToast({
                description: intl.formatMessage(
                    {
                        id: 'basket.reached-max-order'
                    },
                    { maxItems: maxOrder.toString() }
                )
            });
            setTimeout(() => setIsLoading(false), 3000);
            return;
        }

        if (cartItemsUnlogged.length < (BasketConfig.pageSize as number)) {
            await unloggedIncreaseItemQuantity(
                id,
                quantity,
                +grossPrice.replace(',', '.'),
                catalogue
            );

            setQuantity?.(quantity);

            successToast({
                title: intl.formatMessage({ id: 'added-to-cart' })
            });
        } else {
            errorToast({
                title: intl.formatMessage({ id: 'add-to-cart.max-page-size.title' }),
                description: intl.formatMessage({ id: 'add-to-cart.max-page-size.description' })
            });
        }

        setTimeout(() => setIsLoading(false), 3000);
    };

    return (
        <Button
            onClick={() => addToCart(obj.code, obj.quantity, obj.grossPrice)}
            display="flex"
            justifyContent="center"
            minW={28}
            px={4}
            colorScheme="blue"
            borderRadius="full"
            h="52px"
            isLoading={isLoading}
            isDisabled={isLoading}
            {...rest}
        >
            <Flex
                as="span"
                fontWeight="semibold"
                alignItems="center"
                justifyContent="center"
                pointerEvents="none"
            >
                <>{icon || <BasketIcon pointerEvents="none" h="20px" w="auto" />}</>
                <Text ml={2} fontWeight="bold">
                    <FormattedMessage id="add-to-cart" />
                </Text>
            </Flex>
        </Button>
    );
};

export default AddToCartUnlogged;
