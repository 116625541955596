import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { H3 } from '@/components/Heading';
import { NextImage } from '@/components/Image';
import { GoToLink } from '@/components/UI/Links/GoToLink';
import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';
import {
    NewsCardProps,
    OpportunitieQuicklookProps,
    SingleArticleProps
} from '@/models/props/NewsArticleProps';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

type SectionId = Pick<NewsCardProps, 'id'>;

const SingleArticleCard = (props: SingleArticleProps & SectionId) => {
    const { article, id } = props;
    const { attributes } = article;
    const [src, setSrc] = useState<string>('');
    const image =
        id === 'News'
            ? attributes.image?.data.attributes.url
            : id === 'Opportunities' &&
              (attributes.quicklook?.data as OpportunitieQuicklookProps[])?.[0]?.attributes.url;
    const alt =
        id === 'News'
            ? attributes.image?.data.attributes.alternativeText
            : id === 'Opportunities' &&
              (attributes.quicklook?.data as OpportunitieQuicklookProps[])?.[0]?.attributes
                  .alternativeText;

    useEffect(() => {
        const imageURL = StrapiUtilsService.updateUploadsUrl(image as string);

        setSrc(imageURL);
    }, [image, src]);

    return (
        <SimpleGrid
            columns={{ base: 1, md: 2 }}
            borderRadius={10}
            as="article"
            bgColor="grey.100"
            overflow="hidden"
        >
            <Flex
                px={{ base: 4, md: 7 }}
                pb={{ base: 4, md: 6 }}
                pt={{ base: 4, md: 9 }}
                order={{ base: 2, md: 1 }}
                minH={{ base: '150px', md: 'auto' }}
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <H3 marginBottom={4} size="h4">
                    <NavLink href={`${RoutePath[id]}/${attributes.slug}`}>
                        {attributes.title}
                    </NavLink>
                </H3>
                <GoToLink href={`${RoutePath[id]}/${attributes.slug}`} />
            </Flex>
            <Flex pos="relative" overflow="hidden" w="100%" order={{ base: 1, md: 2 }} h={200}>
                {src ? (
                    <NextImage
                        src={src}
                        alt={alt ? alt : attributes.title}
                        width={300}
                        height={200}
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                        display="flex"
                        justifyContent="flex-end"
                    />
                ) : (
                    <Box width={300} height={200} bgColor="grey.200"></Box>
                )}
            </Flex>
        </SimpleGrid>
    );
};

export default SingleArticleCard;
