const breakpoints = {
    xs: 320,
    sm: 560,
    md: 768,
    lg: 960,
    xl: 1200,
    '2xl': 1360,
    '3xl': 1600
};

export default breakpoints;
