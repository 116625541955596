import { Container, Grid, GridItem } from '@chakra-ui/react';

import Classes from './banners.module.scss';

import { BannerCard } from '@/components/Banner/BannerCard';

import { BannerProps } from '@/models/props/BannerProps';

type BannerDataProps = {
    data: BannerProps[];
};

export const BannerSection = ({ data }: BannerDataProps) => {
    return (
        data && (
            <Container as="section" maxW="container.3xl" pt={3} mb={7}>
                <Grid className={Classes.gridContainer}>
                    {data.map(
                        (item) =>
                            item.attributes.image && (
                                <GridItem key={item.id}>
                                    <BannerCard id={item.id} attributes={item.attributes} />
                                </GridItem>
                            )
                    )}
                </Grid>
            </Container>
        )
    );
};
