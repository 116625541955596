import { Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

import SingleArticleCard from '@/components/Card/SingleArticleCard';

import { NewsCardProps } from '@/models/props/NewsArticleProps';
import breakpoints from '@/utils/Breakpoints';

export const NewsCarousel = (props: NewsCardProps) => {
    const { news, id } = props;
    const { data } = news;

    const newsCards = data.map((article, index) => {
        return (
            <SwiperSlide key={index}>
                <SingleArticleCard article={article} id={id} />
            </SwiperSlide>
        );
    });

    return (
        <>
            <style global jsx>
                {`
                    .swiper {
                        display: flex;
                    }
                    .swiper-wrapper {
                        height: inherit;
                    }
                    .swiper-slide {
                        display: flex;
                    }
                `}
            </style>
            <Box pos="relative" overflow="hidden">
                <Box width={{ base: '175%', sm: '150%', md: '125%' }}>
                    <Swiper
                        spaceBetween={16}
                        breakpoints={{
                            [breakpoints.xs]: {
                                slidesPerView: 2
                            },
                            [breakpoints.md]: {
                                slidesPerView: 3
                            }
                        }}
                    >
                        {newsCards}
                        <SwiperSlide></SwiperSlide>
                    </Swiper>
                </Box>
            </Box>
        </>
    );
};
