import { Box } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

import { ProductCard } from '@/components/Card/ProductCard';
import { ProductCardUnlogged } from '@/components/Card/ProductCartUnlogged';

import { CategoryProductListProps } from '@/models/props/ProductCardProps';
import breakpoints from '@/utils/Breakpoints';

const ProductsCarousel = (props: Pick<CategoryProductListProps, 'items'>) => {
    const { items } = props;
    const session = useSession();
    const Card = session.status === 'authenticated' ? ProductCard : ProductCardUnlogged;

    const productsCards = items.map((item) => {
        return (
            <SwiperSlide key={item.code}>
                <Card
                    code={item.code}
                    favourite={item.favourite}
                    slug={item.slug}
                    categories={item.categories}
                    currency={item.currency}
                    discount={item.discount}
                    grossPrice={item.grossPrice}
                    grossPriceBeforeDiscount={item.grossPriceBeforeDiscount}
                    lowestPriceIn30Days={item.lowestPriceIn30Days}
                    isNew={item.new}
                    mainImage={item.mainImage}
                    maximalProductCountInOrder={item.maximalProductCountInOrder}
                    netPrice={item.netPrice}
                    netPromoPrice={item.netPromoPrice}
                    netUnitPrice={item.netUnitPrice}
                    points={item.points}
                    productAvailableForSale={item.productAvailableForSale}
                    sale={item.sale}
                    title={item.title}
                    unit={item.unit}
                />
            </SwiperSlide>
        );
    });

    return (
        <>
            <style global jsx>
                {`
                    .swiper {
                        display: flex;
                    }
                    .swiper-wrapper {
                        height: inherit;
                    }
                    .swiper-slide {
                        display: flex;
                    }
                `}
            </style>
            <Box pos="relative" overflow="hidden">
                <Box width={{ base: '175%', sm: '150%', md: '125%' }}>
                    <Swiper
                        spaceBetween={16}
                        breakpoints={{
                            [breakpoints.xs]: {
                                slidesPerView: 2
                            },
                            [breakpoints.md]: {
                                slidesPerView: 3
                            }
                        }}
                    >
                        {productsCards}
                        <SwiperSlide></SwiperSlide>
                    </Swiper>
                </Box>
            </Box>
        </>
    );
};

export default ProductsCarousel;
