export class HtmlUtil {
    static splitByUrl(content: string) {
        const escapedSingleQuotes = content.replace(/'/g, "\\'");

        const pattern = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /((?:http[s]?:\/\/|ftp:\/\/|mailto:|www|\\n|\n\.)?[-a-zA-Z0-9@%._+~#=]{1,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&\/=]*)/,
            'gmi'
        );

        return escapedSingleQuotes.split(pattern).map((item) => {
            return { content: item, url: pattern.test(item) };
        });
    }

    static getRawText(html: string) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }
}
