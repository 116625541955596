import { Box, List, ListItem, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ProductCard } from '@/components/Card/ProductCard';
import { ProductCardUnlogged } from '@/components/Card/ProductCartUnlogged';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { BaseSection } from '@/layouts';
import ProductsCarousel from '@/layouts/ProductsNewsSection/ProductsCarousel';
import { CategoryProductListProps } from '@/models/props/ProductCardProps';

export const ProductNewsList = (props: Pick<CategoryProductListProps, 'items'>) => {
    const { items = [] } = props;
    const session = useSession();

    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    const intl = useIntl();
    const Card = session.status === 'authenticated' ? ProductCard : ProductCardUnlogged;
    const productCards = items.map((item, index) => {
        return (
            <Box as={ListItem} key={index}>
                <Card
                    code={item.code}
                    favourite={item.favourite}
                    slug={item.slug}
                    categories={item.categories}
                    currency={item.currency}
                    discount={item.discount}
                    grossPrice={item.grossPrice}
                    grossPriceBeforeDiscount={item.grossPriceBeforeDiscount}
                    lowestPriceIn30Days={item.lowestPriceIn30Days}
                    isNew={item.new}
                    mainImage={item.mainImage}
                    maximalProductCountInOrder={item.maximalProductCountInOrder}
                    netPrice={item.netPrice}
                    netPromoPrice={item.netPromoPrice}
                    netUnitPrice={item.netUnitPrice}
                    points={item.points}
                    productAvailableForSale={item.productAvailableForSale}
                    sale={item.sale}
                    title={item.title}
                    unit={item.unit}
                />
            </Box>
        );
    });

    if (!items.length) {
        return <></>;
    }

    return (
        <BaseSection
            title={intl.formatMessage({ id: 'see-new-products' })}
            fullSiteLink={RoutePath.NewProducts}
            fullSiteLinkLabel={intl.formatMessage({ id: 'see-more-new-products' })}
        >
            {!desktop ? (
                <ProductsCarousel items={items} />
            ) : (
                <SimpleGrid as={List} columns={[1, 2, 3, 5]} gap={4}>
                    {productCards}
                </SimpleGrid>
            )}
        </BaseSection>
    );
};
