import { deleteCookie, getCookie } from 'cookies-next';
import type { GetServerSidePropsContext, NextPage } from 'next';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { useEffect } from 'react';

import { withSession } from '@/lib/session';
import { withCommonServerSideProps } from '@/lib/withCommonServerSideProps';

import { BannerSection } from '@/components/Banner/BannerSection';
import Seo from '@/components/Seo';
import { SponsorPopup } from '@/components/SponsorPopup/SponsorPopup';

import { CookieKeys } from '@/constants/cookie-keys';
import { NewsSection } from '@/layouts/NewsSection/NewsSection';
import { ProductNewsList } from '@/layouts/ProductsNewsSection/ProductNewsList';
import { HomePageProps } from '@/models/props/HomePageProps';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { CategoryProductListProps } from '@/models/props/ProductCardProps';
import { APP_STAGING } from '@/pages/_app';
import { BannerService, NewsService, ProductService } from '@/services';
import { OpportunitiesService } from '@/services/OpportunitiesService';
import filterByDates from '@/utils/FilterByDates';

import { useApp } from '../../context/appContext';

const Home: NextPage<HomePageProps> = (props) => {
    const {
        articlesList,
        bannerList,
        productNewsList,
        session,
        showLoginDisabledPopup,
        showOrderingDisabledPopup,
        showSponsorPopup
    } = props;
    const { showDisabledLoginPopup, showDisabledOrderingPopup } = useApp();

    useEffect(() => {
        if (showLoginDisabledPopup) {
            showDisabledLoginPopup();
            return;
        }
        if (showOrderingDisabledPopup) {
            showDisabledOrderingPopup();
            return;
        }
    }, [
        showDisabledLoginPopup,
        showDisabledOrderingPopup,
        showLoginDisabledPopup,
        showOrderingDisabledPopup
    ]);

    return (
        <>
            <Seo />
            <BannerSection data={bannerList} />
            <ProductNewsList items={productNewsList} />
            {showSponsorPopup && <SponsorPopup />}
            {session?.user ? (
                <NewsSection news={articlesList} sectionTite="news" id="News" />
            ) : (
                <>
                    {APP_STAGING > 1 && (
                        <NewsSection news={articlesList} sectionTite="join-us" id="Opportunities" />
                    )}
                </>
            )}
        </>
    );
};

export const getServerSideProps = withCommonServerSideProps(
    withSession(async (context: GetServerSidePropsContext) => {
        const session = await getSession(context);
        const { locale } = context;
        const articlesList = await fetchArticles(session, locale as LocaleProps);
        const bannerList = await fetchBanners(session, locale as LocaleProps);
        const productNewsList = (await fetchProducts(context)).items;
        const { req, res } = context;
        const showLoginDisabledPopup = !!getCookie(CookieKeys.showLoginDisabledPopup, { req, res });
        const showOrderingDisabledPopup = !!getCookie(CookieKeys.showOrderingDisabledPopup, {
            req,
            res
        });
        const showSponsorPopup = !!getCookie(CookieKeys.showSponsorPopup, { req, res });
        deleteCookie(CookieKeys.showLoginDisabledPopup, { req, res });
        deleteCookie(CookieKeys.showOrderingDisabledPopup, { req, res });
        deleteCookie(CookieKeys.showSponsorPopup, { req, res });

        return {
            props: {
                session,
                articlesList,
                bannerList,
                productNewsList,
                showLoginDisabledPopup,
                showOrderingDisabledPopup,
                showSponsorPopup
            }
        };
    })
);

async function fetchArticles(session: Session | null, locale: LocaleProps) {
    try {
        return session
            ? await NewsService.getLatestNews(locale as LocaleProps, 3, 1, 'orderNumber', 'desc')
            : await OpportunitiesService.getAllOpportunities(locale as LocaleProps, 3);
    } catch {
        return [];
    }
}

async function fetchBanners(session: Session | null, locale: LocaleProps) {
    try {
        const data = await BannerService.getBannersLogged(
            locale as LocaleProps,
            session ? 'showToLoggedInUser' : 'showToNonLoggedInUser'
        );

        return filterByDates(data);
    } catch {
        return [];
    }
}

// eslint-disable-next-line unused-imports/no-unused-vars
async function fetchProducts(
    context: GetServerSidePropsContext
): Promise<CategoryProductListProps> {
    const session = await getSession(context);

    try {
        return await ProductService.getNewProducts({
            pageSize: 5,
            opts: {
                session
            }
        });
    } catch {
        return {
            currentPage: 1,
            totalCount: 0,
            totalPageCount: 0,
            items: []
        };
    }
}

export default Home;
