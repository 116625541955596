import { EnvironmentService } from '@/services/EnvironmentService';

export class URLUtil {
    static getURL(url: string): string {
        const pattern = /^((http|https):\/\/)/;

        if (pattern.test(url)) {
            return url;
        }

        url = url || '';

        if (!url.startsWith('/')) {
            url = '/'.concat(url);
        }

        if (!url.endsWith('/')) {
            url = url.concat('/');
        }

        return EnvironmentService.getCanonicalURL().concat(url);
    }

    static addHttpsProtocol(url: string) {
        const protocolRegExp = new RegExp(/^(http[s]?:)+/);
        while (url.startsWith('/')) {
            url = url.substring(1);
        }
        if (protocolRegExp.test(url)) {
            return url;
        }
        return `https://${url}`;
    }
}
